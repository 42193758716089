// @ts-ignore
import { candidateAxios } from '../api/axios';

export const CandidateService = {
  verifySession,
  getCandidateProfileByEmail,
};

async function getCandidateProfileByEmail(body: any) {
  // @ts-ignore
  return candidateAxios.post(`/candidates/getCandidateProfileByEmail`, body);
}

async function verifySession() {
  const body = {
    idToken: sessionStorage.getItem('idtoken'),
    accessToken: sessionStorage.getItem('accesstoken'),
  };
  // @ts-ignore
  return await candidateAxios.post(`/candidateAuthentication/verifySession`, body);
}
