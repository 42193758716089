import React from 'react';
import { H1 } from '@amzn/stencil-react-components/text';
import { PageHeader, PageHeaderDropdown, PageHeaderDropdownButton } from '@amzn/stencil-react-components/page';
import { Row } from '@amzn/stencil-react-components/layout';
import { IconExternalLink, IconGlobe, IconSize } from '@amzn/stencil-react-components/icons';
import { MessageBanner, MessageBannerLink } from '@amzn/stencil-react-components/message-banner';
import constants from '../../Utils/Constants';
import {
  DEFAULT_SUPPORTED_LANGUAGES,
  getGrievanceChatbotUrl,
  getLanguageOptions,
  isMENACountry,
  LANGUAGES,
} from '../../Utils/CountryConstant';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import useArb, { Locale } from '../../hooks/useArb';

export default function HeaderTab() {
  const { t, locale, setLocale } = useArb('appStrings');
  const [countryCode] = useSessionStorage('countryCode', 'other', { parseJSON: false });
  const [supportedLanguages] = useLocalStorage(
    constants.FEATURE_FLAGS.SUPPORTED_LANGUAGES,
    DEFAULT_SUPPORTED_LANGUAGES,
  );
  const languageOptions = getLanguageOptions(countryCode, supportedLanguages);
  const grievanceChatbotUrl = getGrievanceChatbotUrl();
  const [grievanceFeatureFlag] = useLocalStorage(constants.FEATURE_FLAGS.GRIEVANCE_REPORTING, false);

  return (
    <React.Fragment>
      {/* App Header */}
      <PageHeader isFixed={true} hasShadow={false}>
        <Row alignItems="center" justifyContent="space-between" flex="1">
          <H1 fontSize="T400" fontWeight="medium" color="white" textAlign="center">
            {t('PHO-application-title', 'Pre-hire orientation')}
          </H1>

          <PageHeaderDropdown
            icon={<IconGlobe title="Change Language" />}
            items={languageOptions.map((item) => (
              <PageHeaderDropdownButton onClick={() => setLocale(item?.shortName as Locale)}>
                {item?.longName}
              </PageHeaderDropdownButton>
            ))}
          >
            {LANGUAGES[locale]?.longName}
          </PageHeaderDropdown>
        </Row>
      </PageHeader>

      {/* SR Grievance Banner */}
      {isMENACountry() && grievanceFeatureFlag && (
        <MessageBanner>
          {t(
            'PHO-grievance',
            'You deserve fair and equal recruitment practices. However, if you experience a violation during the hiring process, report an issue',
            {
              a: (chunk: any) => (
                <MessageBannerLink
                  href={grievanceChatbotUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<IconExternalLink size={IconSize.ExtraSmall} />}
                >
                  {chunk}
                </MessageBannerLink>
              ),
            },
          )}
        </MessageBanner>
      )}
    </React.Fragment>
  );
}
