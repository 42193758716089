type Stage = 'beta-eu' | 'gamma-eu' | 'prod-eu';

type StageConfig = {
  authority: string;
  client_id: string;
  redirect_uri: string;
  identity_provider: string;
};

const STAGE_CONFIG: Record<Stage, StageConfig> = {
  'beta-eu': {
    authority: 'https://learnings-beta-eu.auth.eu-west-1.amazoncognito.com',
    client_id: '733maj9p2is7aeku48tgv3uunk',
    // redirect_uri: 'http://localhost:3000',
    redirect_uri: 'https://beta.eu.learnings.hvh.a2z.com',
    identity_provider: 'hvh-learning-sso-idp-beta-eu',
  },
  'gamma-eu': {
    authority: 'https://learnings-gamma-eu.auth.eu-west-1.amazoncognito.com',
    client_id: '7sje1j7g3csr75ae3r1rls3e6',
    redirect_uri: 'https://gamma.eu.learnings.hvh.a2z.com',
    identity_provider: 'hvh-learning-sso-idp-gamma-eu',
  },
  'prod-eu': {
    authority: 'https://learnings-prod-eu.auth.eu-west-1.amazoncognito.com',
    client_id: '5k8d0cr5q5gkuonr3d2pi6c2q9',
    redirect_uri: 'https://prod.eu.learnings.hvh.a2z.com',
    identity_provider: 'hvh-learning-sso-idp-prod-eu',
  },
};

export const createAuthConfig = () => {
  // Get current URL parameters
  const currentUrl = new URL(window.location.href);
  const urlParams = new URLSearchParams(currentUrl.search);
  const loginHint = urlParams.get('login_hint');

  // Determine stage from hostname
  const hostname = window.location.hostname;
  let stage: Stage = 'beta-eu';

  if (hostname.includes('gamma.eu')) {
    stage = 'gamma-eu';
  } else if (hostname.includes('prod.eu')) {
    stage = 'prod-eu';
  } else if (hostname === 'localhost') {
    // For local development, you can override stage using URL parameter
    const stageParam = urlParams.get('stage') as Stage | null;
    if (stageParam && stageParam in STAGE_CONFIG) {
      stage = stageParam;
    }
  }

  const stageConfig = STAGE_CONFIG[stage];

  // Store the original URL parameters in sessionStorage to restore after auth
  if (currentUrl.pathname !== '/' && currentUrl.search) {
    sessionStorage.setItem('post_auth_path', currentUrl.pathname);
    sessionStorage.setItem('post_auth_params', currentUrl.search);
  }

  return {
    authority: stageConfig.authority,
    client_id: stageConfig.client_id,
    redirect_uri: stageConfig.redirect_uri,
    response_type: 'code',
    scope: 'email openid profile',
    identity_provider: stageConfig.identity_provider,
    metadata: {
      authorization_endpoint: `${stageConfig.authority}/oauth2/authorize`,
      token_endpoint: `${stageConfig.authority}/oauth2/token`,
      end_session_endpoint: `${stageConfig.authority}/logout`,
    },
    ...(loginHint && {
      extraQueryParams: {
        login_hint: loginHint,
      },
    }),
  };
};
