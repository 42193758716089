export function sortModulesOnPriority(modules) {
  return modules.sort(function (a, b) {
    return parseInt(a.priority) - parseInt(b.priority);
  });
}

export function getProperName(body) {
  const firstName = body.firstName ? body.firstName : '';
  const middleName = body.middleName ? body.middleName : '';
  const lastName = body.lastName ? body.lastName : '';
  return firstName + ' ' + middleName + ' ' + lastName;
}
