import { Row, View, ViewProps } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import SubMenuItem from './SubMenuItem';
import MenuIcon from './MenuIcon';
import { styledWithTheme } from '@amzn/stencil-react-components/context';

interface MenuItemProps extends ViewProps {
  menu: IModule;
  isActive: boolean;
  hideSelection: boolean;
  activeSubMenuId: string;
}

// TODO: Similar to SubMenuItem's StyledRow
const StyledRow = styledWithTheme(Row)<{ isActive: boolean }>(
  ({
    theme: {
      selectors: { color },
    },
    isActive,
  }) => ({
    height: '56px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1.5rem',
    backgroundColor: isActive ? color('primary70') : color('neutral0'),
  }),
);

export default function MenuItem({ menu, isActive, activeSubMenuId, ...props }: MenuItemProps) {
  return (
    <View style={isActive && !activeSubMenuId ? { background: '#F1F4F7' } : {}} {...props}>
      <StyledRow isActive={isActive && !activeSubMenuId} {...props}>
        <Text fontSize="T200" color={isActive && !activeSubMenuId ? 'neutral0' : 'neutral90'}>
          {menu.moduleName}
        </Text>
        <MenuIcon status={menu.status} />
      </StyledRow>

      {menu.contents && isActive && (
        <View>
          {menu.contents.map((content: IContent, index: number) => (
            <SubMenuItem
              key={content.contentId}
              content={content}
              index={index}
              isActive={activeSubMenuId === content.contentId}
            />
          ))}
        </View>
      )}
    </View>
  );
}
