import { Fragment, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row, Spacer } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { useBreakpoints } from '@amzn/stencil-react-components/responsive';
import { DetailedCheckbox, InputFooter, DetailedRadio, FormWrapper } from '@amzn/stencil-react-components/form';
import { IconSize, IconArrowRight, IconArrowLeft, IconCheckCircleFill } from '@amzn/stencil-react-components/icons';
import PlayerScreen from './PlayerScreen';
import { ProgressBar, ProgressBarStatus } from '@amzn/stencil-react-components/progress-bar';
import { View } from '@amzn/stencil-react-components/layout';
import constants from '../../Utils/Constants';
import useArb from '../../hooks/useArb';
import { LiveRegion, LiveRegionRole } from '@amzn/stencil-react-components/live-announcer';
import { styledWithTheme } from '@amzn/stencil-react-components/context';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';

interface IModuleProps {
  state: Array<IModule>;
  activeMenu: IModule;
  activeSubMenu: IContent;
  updateModuleStatus: Function;
  goBack: Function;
}

const StyledInputFooter = styledWithTheme(InputFooter)<{ success?: boolean }>(({ success, theme }) => ({
  ...(success && {
    backgroundColor: theme.selectors.color('green05'),
  }),
}));

export default function ModulePage(props: IModuleProps) {
  const { t, locale } = useArb('appStrings');
  const numberFormatter = useMemo(() => new Intl.NumberFormat(locale.split('-')?.[0]), [locale]);

  const { matches: deviceResolution } = useBreakpoints();
  const [wrongAnswer, setWrongAnswer] = useState(false);
  const [isClicked, setIsClicked] = useState<string[]>([]);
  const [isWrong, setIsWrong] = useState<string[]>([]);
  const [isCorrect, setIsCorrect] = useState<string[]>([]);
  const [activeMenu, setActiveMenu] = useState(props.activeMenu);
  const [activeSubMenu, setActiveSubMenu] = useState(props.activeSubMenu);
  const [isBrokenImage, setIsBrokenImage] = useState(false);

  useEffect(() => {
    if (props.activeMenu?.moduleId) {
      setIsCorrect([]);
      setIsClicked([]);
      let tsubmenu = props.activeSubMenu;
      if (props.activeSubMenu && props.activeSubMenu.status === constants.PHO.STATUS.COMPLETED) {
        setIsCorrect(props.activeSubMenu.answer as string[]);
        setIsClicked(props.activeSubMenu.answer as string[]);
      } else if (tsubmenu && props.activeSubMenu.question_completed) {
        tsubmenu.question_completed = false;
      }
      setActiveMenu(props.activeMenu);
      setActiveSubMenu(tsubmenu);
    }
  }, [props.activeMenu, props.activeSubMenu]);

  const chooseAnswer = (value: string) => {
    setWrongAnswer(false);
    setIsWrong([]);
    setIsCorrect([]);
    setIsClicked([value]);
  };

  // Function to handle multiple answer selection
  const chooseMultiAnswers = (value: string) => {
    setWrongAnswer(false);
    setIsWrong([]);
    setIsCorrect([]);
    // If the selected value has already been clicked, deselect it. Otherwise, select it.
    setIsClicked((prev) => (prev.includes(value) ? prev.filter((option) => option !== value) : [...prev, value]));
  };

  // Function to check the selected answer(s)
  const checkAnswer = () => {
    const { contentType, answer } = activeSubMenu;
    let isCorrect = false;
    let correctAnswers: string[] = [];
    let wrongAnswers: string[] = [];

    if (contentType === constants.INPUT_TYPES.USER_INPUT_SINGLE_SELECT) {
      isCorrect = true;
      correctAnswers = isClicked;
    } else if (contentType === constants.INPUT_TYPES.SINGLE_SELECT) {
      isCorrect = answer.includes(isClicked[0]);
      correctAnswers = isCorrect ? isClicked : [];
      wrongAnswers = isCorrect ? [] : isClicked;
    } else {
      // Multiple select
      isCorrect = isClicked.every((ans) => answer.includes(ans)) && isClicked.length === answer.length;
      correctAnswers = isClicked.filter((ans) => answer.includes(ans));
      wrongAnswers = isClicked.filter((ans) => !answer.includes(ans));
    }

    setWrongAnswer(!isCorrect);
    setIsWrong(wrongAnswers);
    setIsCorrect(correctAnswers);
    setActiveSubMenu((prev) => ({ ...prev, question_completed: isCorrect }));
  };

  const goNext = () => {
    let userInput = '';
    if (activeSubMenu && activeSubMenu.contentType.includes('USER_INPUT')) {
      userInput = activeSubMenu.contentData.findIndex((ans) => ans === isClicked[0]) + '';
    }
    setIsClicked([]);
    setIsCorrect([]);
    setIsWrong([]);
    props.updateModuleStatus(userInput);
  };

  const resetState = () => {
    setIsClicked([]);
    setIsCorrect([]);
    setIsWrong([]);
    setWrongAnswer(false);
    resetInputs();
  };

  // TODO: BAD PRACTICE... Do not manipulate DOM directly!
  const resetInputs = () => {
    const inputs = document.querySelectorAll('input');
    inputs.forEach((input: HTMLInputElement) => {
      input.disabled = false;
      input.checked = false;
    });
  };

  const goBack = () => {
    resetState();
    props.goBack();
  };

  const showFormError = () => {
    setWrongAnswer(true);
  };

  return (
    <View
      style={{
        width: deviceResolution.l || deviceResolution.xl ? '74%' : '100%',
        height: '100%',
        zIndex: 5,
        paddingBottom: deviceResolution.l || deviceResolution.xl ? '0px' : '70px',
      }}
      className="sidenav"
    >
      <Col
        width={'100%'}
        gridGap={'S400'}
        style={{
          padding: deviceResolution.l || deviceResolution.xl ? '2.5rem 8rem' : '1rem 1rem',
          paddingBottom: '100px',
        }}
      >
        {(deviceResolution.l || deviceResolution.xl) && (
          <Col width="100%">
            <Row width="100%">
              <Text fontSize="T300" fontWeight="regular" color="neutral90">
                {activeMenu?.moduleName}{' '}
                {activeSubMenu
                  ? `/ ${t('PHO-quiz-module-title', 'Quiz', {
                      number: numberFormatter.format((activeSubMenu.Index ?? 0) + 1),
                    })}`
                  : ''}
              </Text>
            </Row>
            <Col width="25%" margin="10px 0" id="progress-bar">
              <ProgressBar
                aria-labelledby="status-indicator-1"
                progress={activeMenu?.Index ? (activeMenu.Index - 1) / (props.state.length - 1) : 0}
                status={ProgressBarStatus.Positive}
              />
            </Col>
          </Col>
        )}
        <Row width="100%" height="auto" id="content-image">
          {
            //We are hiding player screen from the feedback module
            props.state &&
            props.state[props.state.length - 1]?.moduleId !== activeMenu?.moduleId &&
            !!activeMenu?.resourceUrl ? (
              activeMenu?.resourceUrl.split('.')[1] === 'mp4' ? (
                <PlayerScreen
                  path={
                    deviceResolution.l || deviceResolution.xl ? activeMenu.resourceUrl : activeMenu.resourceUrlMobile
                  }
                  altText={activeMenu.resourceAltText}
                />
              ) : (
                <Container alignItems="center" style={{ marginTop: '10px' }}>
                  {isBrokenImage ? (
                    <Text fontSize={'T200'} fontWeight="regular" color="neutral90">
                      {activeMenu.resourceAltText
                        ? activeMenu.resourceAltText
                        : t(
                            'PHO-error-image-not-found',
                            'Unable to show the img: Kindly reach out to the recruiter from the staffing agency to obtain this information',
                          )}
                    </Text>
                  ) : (
                    <img
                      src={`${sessionStorage.getItem('cloudfrontS3Distribution')}/${
                        deviceResolution.l || deviceResolution.xl
                          ? activeMenu.resourceUrl
                          : activeMenu.resourceUrlMobile
                      }`}
                      onError={() => {
                        setIsBrokenImage(true);
                      }}
                      alt={
                        activeMenu.resourceAltText
                          ? activeMenu.resourceAltText
                          : t(
                              'PHO-error-image-not-found',
                              'Unable to show the img: Kindly reach out to the recruiter from the staffing agency to obtain this information',
                            )
                      }
                      style={{ width: '100%' }}
                    />
                  )}
                </Container>
              )
            ) : (
              <></>
            )
          }
        </Row>

        <form>
          <fieldset style={{ border: 0, padding: 0 }}>
            {(activeSubMenu?.description || activeMenu?.description) && (
              <legend>
                <Text>
                  {activeSubMenu
                    ? activeSubMenu.description
                    : activeMenu.description
                      ? t('PHO-note', 'Note: ', {
                          information: activeMenu.description,
                          b: (chunk: any) => chunk,
                        })
                      : ''}
                </Text>
              </legend>
            )}
            <Spacer height="S300" />
            <FormWrapper rowGap="S200">
              {activeSubMenu &&
                activeSubMenu.contentData.map((current: any, index: number) => (
                  <Fragment>
                    {activeSubMenu.contentType === constants.INPUT_TYPES.MULTI_SELECT ? (
                      <Col>
                        <DetailedCheckbox
                          id={`checkbox-option-${index}`}
                          dataTestId={`checkbox-option-${index}`}
                          titleText={current}
                          onChange={() => chooseMultiAnswers(current)}
                          checked={isClicked.includes(current)}
                          aria-describedby={`option-${index}-alert`}
                        />
                        {isCorrect.includes(current) && (
                          <StyledInputFooter
                            id={`option-${index}-alert`}
                            success
                            icon={<IconCheckCircleFill color="green" size={IconSize.ExtraSmall} aria-hidden={true} />}
                          >
                            {t('PHO-form-correct-feedback', 'Correct Answer')}
                          </StyledInputFooter>
                        )}
                        {(isWrong.includes(current) || (wrongAnswer && !isCorrect.includes(current))) && (
                          <StyledInputFooter id={`option-${index}-alert`} error>
                            {t('PHO-form-wrong-feedback', 'Wrong Answer')}
                          </StyledInputFooter>
                        )}
                      </Col>
                    ) : (
                      <Col>
                        <DetailedRadio
                          id={`radio-option-${index}`}
                          dataTestId={`radio-option-${index}`}
                          titleText={current}
                          onChange={() => chooseAnswer(current)}
                          checked={isClicked.includes(current)}
                          aria-describedby={`option-${index}-alert`}
                        />
                        {isCorrect.includes(current) && (
                          <StyledInputFooter
                            id={`option-${index}-alert`}
                            success
                            icon={<IconCheckCircleFill color="green" size={IconSize.ExtraSmall} aria-hidden={true} />}
                          >
                            {t('PHO-form-correct-feedback', 'Correct Answer')}
                          </StyledInputFooter>
                        )}
                        {isWrong.includes(current) && (
                          <StyledInputFooter id={`option-${index}-alert`} error>
                            {t('PHO-form-wrong-feedback', 'Wrong Answer')}
                          </StyledInputFooter>
                        )}
                      </Col>
                    )}
                  </Fragment>
                ))}
            </FormWrapper>
          </fieldset>
        </form>

        <LiveRegion aria-live="polite" role={LiveRegionRole.Status}>
          {wrongAnswer && (
            <MessageBanner type={MessageBannerType.Error} titleText={t('PHO-form-input-error', 'Incorrect')}>
              {t('PHO-form-input-error-prompt', 'Please choose the appropriate answer.')}
            </MessageBanner>
          )}
        </LiveRegion>

        <Row
          justifyContent="space-between"
          style={{ marginTop: deviceResolution.l || deviceResolution.xl ? '16px' : '0px' }}
        >
          {activeMenu.Index && activeMenu.Index > 1 && (
            <Button
              variant={ButtonVariant.Secondary}
              style={{ padding: '0.5rem 3.5rem', paddingLeft: '3rem', fontWeight: 'normal' }}
              onClick={goBack}
              id="back-button"
            >
              {t('PHO-btn-back', 'Back')}
            </Button>
          )}
          {activeSubMenu ? (
            <Button
              variant={ButtonVariant.Primary}
              style={{ padding: '0.5rem 3.5rem', paddingRight: '3rem', fontWeight: 'normal' }}
              onClick={
                // Disabled state: Allow people who rely on assistive technology to run into error messages
                !activeSubMenu.question_completed && isClicked.length === 0
                  ? showFormError
                  : activeSubMenu.question_completed
                    ? goNext
                    : isWrong.length > 0
                      ? resetState
                      : checkAnswer
              }
              id="continue-button"
            >
              {activeSubMenu.question_completed ? (
                <Row>
                  {t('PHO-btn-next', 'Next')}
                  <IconArrowRight
                    display="inline"
                    margin={{ left: 10, top: 4 }}
                    size={IconSize.ExtraSmall}
                    rtl={IconArrowLeft}
                  />
                </Row>
              ) : isWrong.length > 0 ? (
                t('PHO-btn-reset', 'Reset')
              ) : (
                t('PHO-btn-continue', 'Continue')
              )}
            </Button>
          ) : (
            <Button
              variant={ButtonVariant.Primary}
              onClick={goNext}
              style={{ padding: '0.5rem 3.5rem', paddingRight: '3rem', fontWeight: 'normal' }}
              id="next-button"
            >
              <Row>
                {t('PHO-btn-next', 'Next')}
                <IconArrowRight
                  display="inline"
                  margin={{ left: 10, top: 4 }}
                  size={IconSize.ExtraSmall}
                  rtl={IconArrowLeft}
                />
              </Row>
            </Button>
          )}
        </Row>
      </Col>
    </View>
  );
}
