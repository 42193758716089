// @ts-ignore
import { orgAxios } from '../api/axios';

export const PhoService = {
  getModulesByPhoId,
  getCandidatePhoProgress,
  postCandidatePhoProgress,
  postCandidateHiringStage,
  markPhoComplete,
  resetPhoProgress,
  getAppConfig,
};

async function getAppConfig() {
  // @ts-ignore
  return await orgAxios.get('/getAppConfig');
}

async function getModulesByPhoId(body: { phoId: string; locale: string }) {
  // @ts-ignore
  return await orgAxios.get('/pho/modulesById', {
    params: { phoId: body.phoId, locale: body.locale },
  });
}

async function getCandidatePhoProgress(body: { phoId: string; candidateId: string; applicationId: string }) {
  // @ts-ignore
  return await orgAxios.get('/pho/progress', {
    params: { phoId: body.phoId, candidateId: body.candidateId, applicationId: body.applicationId },
  });
}

async function postCandidatePhoProgress(body: any) {
  // @ts-ignore
  return await orgAxios.post('/pho/progress', body);
}

async function postCandidateHiringStage(body: any) {
  // @ts-ignore
  return await orgAxios.post('/candidate/application/hiringStage', body);
}

async function markPhoComplete(body: any) {
  // @ts-ignore
  return await orgAxios.put('/pho/complete', body);
}

async function resetPhoProgress(body: any) {
  // @ts-ignore
  return await orgAxios.put('/pho/reset', body);
}
