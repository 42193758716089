import { Row, ViewProps } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import MenuIcon from './MenuIcon';
import { styledWithTheme } from '@amzn/stencil-react-components/context';
import useArb from '../../hooks/useArb';
import { useMemo } from 'react';

interface ContentItemProps extends Omit<ViewProps, 'content'> {
  content: IContent;
  isActive: boolean;
  index: number;
}

// TODO: Similar to MenuItem's StyledRow
const StyledRow = styledWithTheme(Row)<{ isActive: boolean }>(
  ({
    theme: {
      selectors: { color },
    },
    isActive,
  }) => ({
    height: '56px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1.5rem',
    backgroundColor: isActive ? color('primary70') : color('neutral05'),
  }),
);

export default function SubMenuItem({ content, isActive, index, ...props }: ContentItemProps) {
  const { t, locale } = useArb('appStrings');
  const numberFormatter = useMemo(() => new Intl.NumberFormat(locale.split('-')?.[0]), [locale]);

  return (
    <StyledRow isActive={isActive} {...props}>
      <Text fontSize="T200" color={isActive ? 'neutral0' : 'neutral90'}>
        {/* TODO: Fix hard-coded value. For now, this is okay since only Quiz sub-modules exist! */}
        {t('PHO-quiz-module-title', 'Quiz', { number: numberFormatter.format(index + 1) })}
      </Text>
      <MenuIcon status={content.status} />
    </StyledRow>
  );
}
