let constants = {
  CONTENT_PREFIX: 'content#',
  INPUT_TYPES: {
    SINGLE_SELECT: 'SINGLE_SELECT',
    USER_INPUT_SINGLE_SELECT: 'USER_INPUT_SINGLE_SELECT',
    MULTI_SELECT: 'MULTI_SELECT',
  },
  TRANSLATE: {
    PHO_LOCALE: 'pho-locale',
  },
  PHO: {
    STAGES: {
      ASSIGNED: 'PHO Assigned',
      IN_PROGRESS: 'PHO In-Progress',
      COMPLETED: 'PHO Completed',
    },
    STATUS: {
      TODO: 'TODO',
      IN_PROGRESS: 'IN_PROGRESS',
      COMPLETED: 'COMPLETED',
    },
    MODULES: {
      WELCOME: 'Welcome',
      SAFETY: 'Safety',
      ABOUT_ALFA: 'About ALFA',
      UNDERSTAND_YOUR_ROLE: 'Understand Your Role',
      PAY_AND_BENEFITS: 'Pay And Benefits',
      LOCATION_AND_TRANSPORT: 'Location And Transportation',
      SHIFT_TIME_AND_WORK_HOURS: 'Shift Timing And Work Hours',
      FEEDBACK: 'Must Know',
    },
    CONTENT_TYPE: {
      SINGLE_SELECT: 'SINGLE_SELECT',
      MULTI_SELECT: 'MULTI_SELECT',
      USER_INPUT_SINGLE_SELECT: 'USER_INPUT_SINGLE_SELECT',
    },
    UPDATE_CANDIDATE_ENTITY_OPERATION: 'UPDATE_CANDIDATE',
  },
  SPECTROMETER: {
    APP_NAME: 'HVHPhoUI',
    SITE_NAME: 'HVHPhoUI',
    STAGE: 'stage',
  },
  CUSTOM_MODULES: {
    PHO_CUSTOM_WELCOME_MODULE: 'PHO-CUSTOM-WELCOME-MODULE',
  },
  // Local Storage keys for Feature Flags
  FEATURE_FLAGS: {
    GRIEVANCE_REPORTING: 'FEATURE_FLAG_ENABLE_GRIEVANCE_REPORTING',
    SUPPORTED_LANGUAGES: 'FEATURE_FLAG_SUPPORTED_LANGUAGES',
  },
};

export default constants;
